module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"KSV Global","short_name":"KSV","start_url":"/","background_color":"#323E54","theme_color":"#AD9378","display":"minimal-ui","icon":"/opt/build/repo/src/assets/images/manifest-512x512.jpg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
